// debug  
// check if URL debug=1
// example: /dealer-locator?debug=0
var debug = false;

if (getQueryVariable("debug") == 1) {
    debug = true;
    debug && console.log("debug= " + debug);
}

window.pListIndex = 0;
window.vListIndex = 0;
const cssClass = "optionfocus";

class Home {

    constructor() {

        // Used for tag firing
        var isFirstLoad = true;

        // Geo code New
        this.initPage = function (zip) {

            if (zip) {
                debug && console.log('Zip is available. START');
                ngScope().initPageNow();

                //only trigger the tag on page load
                if (isFirstLoad) {

                    isFirstLoad = false;

                };

            } else {
                debug && console.log('Zip code not available. Cannot proceed.');
            }

        }

        $(document).ready(function () {
            const pBoxCollection = $(".fnsb-item-programs");
            const vBoxCollection = $(".fnsb-item-vehicles");


            //temp code to redirect to inventory page
            //when static banner is clicked
            $(".static-banner").on('click', function (e) {
                setTimeout(() => {
                    window.location.href = '/vehicle-search?offer=1';
                }, 500);
            });

            // Will be used to detect if video pano is enabled in Sitecore
            var $videoDiv = $('.video-pano');

            // We start the video player
            loadScriptYT();
            var $btn_video = $('.btn-video');
            var $video_overlay = $('.video-overlay');

            // Open video overlay onclick
            $btn_video.on('click', function (e) {
                //get video ID from bttn
                var videoid = $(this).attr("data-videoId");
                $(".video-wrapper").find("iframe").remove();
                $('<iframe width="100%" height="100%" frameborder="0" id="vehicleYT" allowfullscreen></iframe>').attr("src", "https://www.youtube.com/embed/" + videoid + "?autoplay=1&enablejsapi=1").appendTo(".video-wrapper");
                onYouTubeIframeAPIReady();
                $video_overlay.removeClass('hidden');
                // e.preventDefault();
                });
            
                // Close video overlay onclick
                $('.video-player-background, .video-close').on('click', function (e) {
                //close popup
                $video_overlay.addClass('hidden');
                //remove active video
                $('.video-wrapper').find("iframe").remove();
                // e.preventDefault();
                });
               
            if ( $('.carousel-container')) {
                // Start carousel and update the new carrousel navigation icons
                $('.owl-carousel').owlCarousel({ 
                    loop: true,
                    items: 1,
                    dots: true,
                    lazyLoad: true,
                    nav: true,
                    margin: 0,
                    navText: ['<div class="slide-arrow-left"></div>', '<div class="slide-arrow-right"></div>'],
                });
            } else {
                // We start Carousel
                $('.owl-carousel').owlCarousel({
                    loop: true,
                    items: 1,
                    dots: true,
                    lazyLoad: true,
                    margin: 30
                });
            }

            // Do not show vehicle types on load
            $(".vehicle-model").hide();
            
            // Resize these modules
            if($(window).width() <= 768) {
                $(".benefits-module-used").addClass("row");
                $(".benefits-module-certified").addClass("row");
                $(".passing-torch").addClass("row");
                $(".owl-dots").removeClass("disabled");
            }else{
                $(".benefits-module-used").removeClass("row");
                $(".benefits-module-certified").removeClass("row");
                $(".passing-torch").removeClass("row");
            }

            $(window).on("resize", function() {
                if($(window).width() <= 768) {
                    $(".benefits-module-used").addClass("row");
                    $(".benefits-module-certified").addClass("row");
                    $(".passing-torch").addClass("row");
                    $(".owl-dots").removeClass("disabled");
                }else{
                    $(".benefits-module-used").removeClass("row");
                    $(".benefits-module-certified").removeClass("row");
                    $(".passing-torch").removeClass("row");
                }
            });




            /* Programs Filter controls Sort By Link Button Click */
            $(".filter-nav-sortby-btn-programs").on("click", function () {
                $(".filter-nav-sortby-inner-programs").slideDown(300);
                $(".img-drop-programs").show(300);
                $(this).attr('aria-expanded',true);
                pBoxCollection.removeClass(cssClass).attr('aria-selected',false).eq(pListIndex).addClass(cssClass).attr('aria-selected',true).focus();
             });

            $(".filter-nav-sortby-btn-programs").on("keyup", function (e) {
                e.stopPropagation();
                if (e.key === "Enter") {
                    $(".filter-nav-sortby-inner-programs").slideDown(300);
                    $(".img-drop-programs").show(300);
                    $(this).attr('aria-expanded',true);
                    console.log('vbox',pBoxCollection)
                    pBoxCollection.removeClass(cssClass).attr('aria-selected',false).eq(pListIndex).addClass(cssClass).attr('aria-selected',true).focus();
                }
             });

   
             $('.fnsb-item-programs').on('keyup', function(e){
                e.stopPropagation();
                e.preventDefault();
                if (e.key === 'ArrowDown') {  
                    programsNavigate(1);
                }
                if(e.key === 'ArrowUp') {
                    programsNavigate(-1);
                }
                if (e.key === 'Enter' && $(".filter-nav-sortby-btn-programs").attr('aria-expanded') == 'true') {
                    $(this).trigger('click');
                }
             })

            /* Filter controls Sort By Option Click */
            $(".fnsb-item-programs").on("click", function () {
                $(".filter-nav-sortby-btn-programs").attr('aria-expanded',false);
                $(".filter-nav-sortby-btn-programs").focus();
                $(".filter-nav-sortby-btn-programs").html($(this).html() + '<img src="/images/map/icon-sortby-down.png" alt="">');
                $(".filter-nav-sortby-inner-programs").slideUp(300);
                $(".img-drop-programs").hide();
            });





            /* Vehicles Filter controls Sort By Link Button Click */
            $(".filter-nav-sortby-btn-vehicles").on("click", function () {
                $(".filter-nav-sortby-inner-vehicles").slideDown(400);
                $(".img-drop-vehicles").show(300);
                $(this).attr('aria-expanded',true);
                vBoxCollection.removeClass(cssClass).attr('aria-selected',false).eq(vListIndex).addClass(cssClass).attr('aria-selected',true).focus();
            });

            $(".filter-nav-sortby-btn-vehicles").on("keyup", function (e) {
                e.stopPropagation();
                e.preventDefault();
                if (e.key === "Enter") {
                    $(".filter-nav-sortby-inner-vehicles").slideDown(400);
                    $(".img-drop-vehicles").show(300);
                    $(this).attr('aria-expanded',true);
                    console.log('vbox',vBoxCollection)
                    vBoxCollection.removeClass(cssClass).attr('aria-selected',false).eq(vListIndex).addClass(cssClass).attr('aria-selected',true).focus();
                }

            });          
            
            $('.fnsb-item-vehicles').on('keyup', function(e){
                e.stopPropagation();
                if (e.key === 'ArrowDown') {  
                    vehiclesNavigate(1);
                }
                if(e.key === 'ArrowUp') {
                    vehiclesNavigate(-1);
                }
                if (e.key === 'Enter' && $(".filter-nav-sortby-btn-vehicles").attr('aria-expanded') == 'true') {
                    $(this).trigger('click');
                }
             })

            /* Filter controls Sort By Option Click */
            $(".fnsb-item-vehicles").on("click", function () {
                $(".filter-nav-sortby-btn-vehicles").attr('aria-expanded',false);
                $(".filter-nav-sortby-btn-vehicles").focus();
                $(".filter-nav-sortby-btn-vehicles").html($(this).html() + '<img src="/images/map/icon-sortby-down.png" alt="">');
                $(".filter-nav-sortby-inner-vehicles").slideUp(400);
                $(".img-drop-vehicles").hide();
            });





            // If a user clicks on a vehicle or type from the homepage, the model name/style is passed onto the Inventory page
            $(".vehicle-model > div").on("click", function () {
                var model = $(this).find('p.model').text();
                var linkText = $(this).text().replace(/\s+/g, ' ').trim();
                var position = ($(".vehicle-model .home-grid-item").index(this) + 1)
                //  FireTapCallBack('vehicles_types', position, 0, 4, model, 'null', 'null', 'vehicles', linkText, 'home-click')
                setTimeout(() => {
                    window.location.href = '/vehicle-search/?ModelGroupName=' + model;
                }, 600);
            });

            $(".vehicle-model > div").on('keypress',function(e) {
                if(e.which == 13) {
                    $(this).trigger('click');
                }
            });

            // Change the color of text to blue on hover
            // $(".vehicle-model > div").hover(function () {
            //     $(this).find('p').css('color', '#0074B3');
            // }, function () {
            //     $(this).find('p').css('color', '#000000');
            // });

            $(".vehicle-type > div").on("click", function () {
                var type = $(this).find('p.model').text();
                var TAPtype = $(this).find('p.model').text();
                if (type == "CARS") {
                    type = "Coupes|Sedans|Hatchbacks";
                } else if (type == "MINIVANS & TRUCKS") {
                    type = "Truck|Minivan";
                } else if (type == "SUVS & CROSSOVERS") {
                    type = "SUVs/CUVs";
                } else if (type == "ELECTRIFIED") {
                    type = "Hybrids";
                }

                var position = ($(".vehicle-type .vehicle-type-item").index(this) + 1)
                //   FireTapCallBack('vehicles_types', position, 0, 4, 'null', 'null', 'null', 'types', TAPtype, 'home-click')
                setTimeout(() => {
                    window.location.href = '/vehicle-search?style=' + type;
                }, 600);
            });

            $(".vehicle-type > div").on('keypress',function(e) {
                if(e.which == 13) {
                    $(this).trigger('click');
                }
            });

            // Change the color of text to blue on hover
            // $(".vehicle-type > div").hover(function () {
            //     $(this).find('p').css('color', '#0074B3');
            // }, function () {
            //     $(this).find('p').css('color', '#000000');
            // });


            // If a user clicks on the 'View All Vehicles Near Me' button, he is redirected to the inventory page with zip and radius = 25 miles
            $(".btn-view-vehicles").on("click", function () {
                var userZip = localStorageGetItemCommon(key);
                window.location.href = '/vehicle-search/?zip=' + userZip + '&radius=25';
            });


            // Make the Explore button fixed at the bottom of the page on smaller desktop devices.
            // var $window = $(window);

            // function checkWindowDimension() {
            //     var windowWidth = $window.width();
            //     var windowHeight = $window.height();
            //     var newHeight;

            //     if (windowWidth > 1024) {

            //         // 960 = height of menu (70) + search bar (80) + video (810)
            //         if (windowHeight > 749 && windowHeight < 960 && $videoDiv.length) {
            //             newHeight = (960 - windowHeight) + 25 + 'px';
            //             $('a.explore').css("bottom", newHeight);
            //             // 910 = height of menu (70) + search bar (80) + pano (760)
            //         } else if (windowHeight > 749 && windowHeight < 910) {
            //             newHeight = (910 - windowHeight) + 25 + 'px';
            //             $('a.explore').css("bottom", newHeight);
            //         } else {
            //             $('a.explore').css("bottom", "25px");
            //         }
            //     }
            // }

            // Execute on load
            // checkWindowDimension();

            // Bind event listener
            // $(window).resize(checkWindowDimension);


            // $(window).scroll(function() {
            //     var windowWidth = $window.width();
            //     var windowHeight = $window.height();
            //     var newHeight;
            //     var scrollValue;

            //     if (windowWidth > 1024) {

            //         // 960 = height of menu (70) + search bar (80) + video (810)
            //         if (windowHeight > 749 && windowHeight < 960 && $videoDiv.length) {
            //             scrollValue = $(window).scrollTop();
            //             newHeight = ((960 - windowHeight) + 25) - scrollValue;
            //             if (newHeight > 25) {
            //                 $('a.explore').css("bottom", newHeight + 'px');
            //             }
            //             // 910 = height of menu (70) + search bar (80) + pano (760)
            //         } else if (windowHeight > 749 && windowHeight < 910) {
            //             scrollValue = $(window).scrollTop();
            //             newHeight = ((910 - windowHeight) + 25) - scrollValue;
            //             if (newHeight > 25) {
            //                 $('a.explore').css("bottom", newHeight + 'px');
            //             }
            //         } else {
            //             $('a.explore').css("bottom", "25px");
            //         }
            //     }
            // });

                        // Check if Promotion pano is enabled in Sitecore
                        var $promoDiv = $('.home-promotion-iframe-wrapper'); 
                        if ($promoDiv.length) {
            
                            // Do not show promo on load
                            $("#home-promotion-iframe, .home-promotion-iframe-wrapper").hide();
                            
                            //Only enable when there are more than 1 animtions and randomized
                           // PromoRandomizer();

                            // Region based offers
                            // RegionalOffers();

                            //Basic offers
                            BasicOffers();
                            window.addEventListener('resize', debounce(BasicOffers, 400), true);
                        }

                        function detectAdBlocker() {
                            //this the Google url used on Views\Shared\Partials\_MetaHead.cshtml for tracking
                            const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
                            fetch(new Request(googleAdUrl))
                            .then(response => {
                                localStorageSetItemCommon('ADBLOCKER_ON', response.redirected);
                                if (response.redirected){
                                    console.log("=======Showing the image", response.redirected);
                                    $('#region_a img').removeClass('hidden');
                                }else {
                                    $('#region_a img').addClass('hidden');
                                }
                            }).catch(() => {
                                $('#region_a img').removeClass('hidden');
                            });                            
                        }

                        function debounce(callback, wait) {
                            let timerId;
                            return (...args) => {
                              clearTimeout(timerId);
                              timerId = setTimeout(() => {
                                callback(...args);
                              }, wait);
                            };
                        }

                        function BasicOffers(){
                            // clear iframe src
                            $('#region_a #home-promotion-iframe').attr("src", "");
                            $('#region_a #home-promotion-iframe-sm').attr("src", "");
                            $('#region_a #home-promotion-iframe-xs').attr("src", "");   
                            //get src for promotions
                            var promoSrc = new Array ();
                            promoSrc[0] = "offer_1";
                            //check if adblocker is enabled by user
                            detectAdBlocker();

                            //show the first offer
                            $("#region_a").removeClass('hidden');
                            $("#home-promotion-iframe, .home-promotion-iframe-wrapper").show();
                            //Apply the defult src
                            if ($('#region_a #home-promotion-iframe:visible').length) {
                                $('#region_a #home-promotion-iframe').attr("src", "images/home/Promotions/current/" + promoSrc[0] + "/1440x762/DAYTONIGHT_1440x762.html");
                            }
                            if($('#region_a #home-promotion-iframe-sm:visible').length) {
                                $('#region_a #home-promotion-iframe-sm').attr("src", "images/home/Promotions/current/" + promoSrc[0] + "/992x661/DAYTONIGHT_992x661.html");
                            }
                            if($('#region_a #home-promotion-iframe-xs:visible').length) {
                                $('#region_a #home-promotion-iframe-xs').attr("src", "images/home/Promotions/current/" + promoSrc[0] + "/640x960/DAYTONIGHT_640x960.html");   
                            }

                            //Include disclaimer
                            $('.home-promotion-iframe-disclaimer .disclaimer-callout').data('disclaimers-content', offer_disclaimer_offer_1); 

                        }


                      var disableRegionalOffers = true;
                      //this func is disable above
                        function RegionalOffers(){
                            //if disableRegionalOffers is not enabled do nothing 
                            if(disableRegionalOffers) return;
                            
                            //get src for promotions
                                var promoSrc = new Array ();
                                promoSrc[0] = "offer_1";
                                promoSrc[1] = "offer_2";
                                // promoSrc[2] = "offer_3";


                                var regionFlag = localStorageGetItemCommon("_GeoCurrentRegion");
                                //Region B can show second promotion
                                // or defualt video 
                                var regionBOption = "video"
                                if (regionFlag == 'A') {
                                    // clear iframe src
                                    $('#region_a #home-promotion-iframe').attr("src", "");
                                    $('#region_a #home-promotion-iframe-sm').attr("src", "");
                                    $('#region_a #home-promotion-iframe-xs').attr("src", "");
                                    console.log('RegionalOffers Region A');

                                    if($('#region_a #home-promotion-iframe:visible').length) {
                                        $('#region_a #home-promotion-iframe').attr("src", "images/home/Promotions/current/" + promoSrc[0] + "/1440x762/dream_deal_1440x762.html");
                                    }
                                    if ($('#region_a #home-promotion-iframe-sm:visible').length) {
                                        $('#region_a #home-promotion-iframe-sm').attr("src", "images/home/Promotions/current/" + promoSrc[0] + "/992x661/dream_deal_992x661.html");                                        
                                    }
                                    if ($('#region_a #home-promotion-iframe-xs:visible').length) {
                                        $('#region_a #home-promotion-iframe-xs').attr("src", "images/home/Promotions/current/" + promoSrc[0] + "/640x960/dream_deal_640x960.html");    
                                    }

                                     // show promo 
                                      $("#region_a").removeClass('hidden');
                                      $("#home-promotion-iframe, .home-promotion-iframe-wrapper").show();

                                      //Include disclaimer
                                      $('.home-promotion-iframe-disclaimer .disclaimer-callout').data('disclaimers-content', offer_disclaimer_offer_1); 


                                } else {
                                    if(regionBOption == 'video'){
                                        console.log('RegionalOffers Region B Video option');
                                        $('.video-pano').show();
                                    } else {
                                        //clear iframe src
                                        $('#region_b #home-promotion-iframe').attr("src", "");
                                        $('#region_b #home-promotion-iframe-sm').attr("src", "");
                                        $('#region_b #home-promotion-iframe-xs').attr("src", "");
                                        console.log('RegionalOffers Region B');
                                        if ($('#region_b #home-promotion-iframe:visible').length) {
                                            $('#region_b #home-promotion-iframe').attr("src", "images/home/Promotions/current/" + promoSrc[1] + "/1440x762/dream_deal_1440x762.html");    
                                        }
                                        if ($('#region_b #home-promotion-iframe-sm:visible').length) {
                                            $('#region_b #home-promotion-iframe-sm').attr("src", "images/home/Promotions/current/" + promoSrc[1] + "/992x661/dream_deal_992x661.html");                                            
                                        }
                                        if ($('#region_b #home-promotion-iframe-xs:visible').length) {
                                            $('#region_b #home-promotion-iframe-xs').attr("src", "images/home/Promotions/current/" + promoSrc[1] + "/640x960/dream_deal_640x960.html");                                            
                                        }
                                        
                                     // show promo 
                                      $("#region_b").removeClass('hidden');
                                      $("#home-promotion-iframe").show();
    
                                            //Include disclaimer
                                            $('.home-promotion-iframe-disclaimer .disclaimer-callout').data('disclaimers-content', offer_disclaimer_offer_2); 
                                    }

                                }
                            

                        };

                        // this function is disabled above
                        function PromoRandomizer(){
                            alert('PromoRandomizer')
                            //clear iframe src
                            $('#home-promotion-iframe').attr("src", "");
                            $('#home-promotion-iframe-sm').attr("src", "");
                            $('#home-promotion-iframe-xs').attr("src", "");

                            //get random src for promotions
                                var promoSrc = new Array ();
                                promoSrc[0] = "offer_1";
                                // promoSrc[1] = "offer_2";
                                // promoSrc[2] = "offer_3";

                                var size = promoSrc.length
                                var x = Math.floor(size*Math.random())

                            console.log('Promotion SOURCE:   ' , promoSrc[x] )
                            // return promoSrc[x];
                            if($('#home-promotion-iframe:visible').length){
                                $('#home-promotion-iframe').attr("src", "images/home/Promotions/current/" + promoSrc[x] + "/1440x762/dream_deal_1440x762.html");
                            }
                            if ($('#home-promotion-iframe-sm:visible').length) {
                                    $('#home-promotion-iframe-sm').attr("src", "images/home/Promotions/current/" + promoSrc[x] + "/992x661/dream_deal_992x661.html");    
                            }
                            if ($('#home-promotion-iframe-xs:visible').length) {
                                $('#home-promotion-iframe-xs').attr("src", "images/home/Promotions/current/" + promoSrc[x] + "/640x960/dream_deal_640x960.html");    
                            }
                            

                            // var currect_disc = "offer_disclaimer_" + promoSrc[x];
                            // console.log('offer dis: ', currect_disc)
                            if (promoSrc[x] == 'offer_1'){
                             $('.home-promotion-iframe-disclaimer .disclaimer-callout').data('disclaimers-content', offer_disclaimer_offer_1); 
                            } else if  (promoSrc[x] == 'offer_2'){
                                $('.home-promotion-iframe-disclaimer .disclaimer-callout').data('disclaimers-content', offer_disclaimer_offer_2); 
                               } else if  (promoSrc[x] == 'offer_3'){
                                $('.home-promotion-iframe-disclaimer .disclaimer-callout').data('disclaimers-content', offer_disclaimer_offer_3); 
                               }
                            
                        // show promo 
                        $("#home-promotion-iframe").show();
                        };

                         

            // Check if video pano is enabled in Sitecore
            // var $videoDiv = $('.video-pano');

            // if ($videoDiv.length) {

            //     getVideo();

            //     // Do not show video on load
            //     $("#video").hide();

            //     // Check if device autoplays video
            //     var video_autoplay = false;

            //     $("#video").on('play', function () {
            //         video_autoplay = true;
            //     })[0].play();

            //     setTimeout(function () {
            //         if (video_autoplay) {
            //             showVideo();
            //         } else {
            //             showPano();
            //         }
            //     }, 100);
            // }

            // Capture the event when user changes the device orientation
            $(window).on("orientationchange", function (event) {
                // mobile portrait orientation
                if (window.innerHeight > window.innerWidth && $videoDiv.length) {
                    debug && console.log('i am portrait');
                    $('#video > source').remove();
                    $('.overlay').css({
                        "width": "100%",
                        "overflow": "hidden",
                        "display": "block",
                        "height": "432px"
                    });
                    $('#video').css({
                        "width": "768px",
                        "height": "432px"
                    });
                    $('#video').append("<source type='video/mp4' src=" + randomizer() + "/768x432.mp4 />");
                }
                // mobile landscape orientation
                if (window.innerWidth > window.innerHeight && $videoDiv.length) {
                    debug && console.log('i am landscape');
                    $('#video > source').remove();
                    $('#video').css({
                        "width": "100% !important",
                        "height": "auto !important"
                    });
                    $('#video').append("<source type='video/mp4' src=" + randomizer() + "/640x360.mp4 />");
                }
            });


            // Check if video pano is enabled in Sitecore
            // if ($videoDiv.length) {

            //     getVideo();

            //     // Do not show video on load
            //     $("#video").hide();

            //     // Check if device autoplays video
            //     var video_autoplay = false;

            //     $("#video").on('play', function () {
            //         video_autoplay = true;
            //     })[0].play();

            //     setTimeout(function () {
            //         if (video_autoplay) {
            //             showVideo();
            //         } else {
            //             showPano();
            //         }
            //     }, 100);
            // }

            // Capture the event when user changes the device orientation
            $(window).on("orientationchange", function (event) {
                // mobile portrait orientation
                if (window.innerHeight > window.innerWidth && $videoDiv.length) {
                    debug && console.log('i am portrait');
                    $('#video > source').remove();
                    $('.overlay').css({
                        "width": "100%",
                        "overflow": "hidden",
                        "display": "block",
                        "height": "432px"
                    });
                    $('#video').css({
                        "width": "768px",
                        "height": "432px"
                    });
                    $('#video').append("<source type='video/mp4' src=" + randomizer() + "/768x432.mp4 />");
                }
                // mobile landscape orientation
                if (window.innerWidth > window.innerHeight && $videoDiv.length) {
                    debug && console.log('i am landscape');
                    $('#video > source').remove();
                    $('#video').css({
                        "width": "100% !important",
                        "height": "auto !important"
                    });
                    $('#video').append("<source type='video/mp4' src=" + randomizer() + "/640x360.mp4 />");
                }
            });

            // var modal = document.getElementById("modal-window");
            // var modalButton = document.getElementById("modal-button");
            // var modalClose = modal.getElementsByClassName("close")[0];

            // modalButton.onclick = function() {
            //     modal.style.display = "block";
            //     modalClose.focus();
            //     return false;
            // }
        
            // modalClose.onclick = function() {
            //     modal.style.display = "none";
            //     modalButton.focus();
            // }
        
            // window.onclick = function(event) {
            //     if (event.target == modal) {
            //         modal.style.display = "none";
            //         modalButton.focus();
            //     }
            // }

            const modal = $('#modal-window');
            const modalButton = $('#modal-button');
            const modalClose = $('#modal-window .close');
            $('#modal-window').attr('aria-hidden','false').attr('aria-label','Learn more about honda service pass').attr('role','dialog');
            $('#modal-window .close').attr('tabindex',"0").attr('role','button').attr('aria-label','close modal')

            const showModal = () => {
                $('#modal-window').attr('aria-hidden','true');
                modal.show();
                modalClose.focus();
            }

            const hideModal = () => {
                $('#modal-window').attr('aria-hidden','false');
                modal.hide();
                modalButton.focus();
            }
            $(document.body).on("click", "#modal-button", function() {
                showModal();
            });

            $(document.body).on("click", "#modal-window .close", function() {
                hideModal();
            });

            $(document.body).on('keypress', "#modal-window .close", function(e) {
                if(e.which == 13) {
                    $(this).trigger('click');
                }
            });

            const trapmodal = document.querySelector('#modal-window');

            window.onclick = function(event) {
                if (event.target == trapmodal) {
                    hideModal();
                }
            };

            const focusableElements = '[href], [tabindex]:not([tabindex="-1"])';
            const firstFocusableElement = trapmodal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
            const focusableContent = trapmodal.querySelectorAll(focusableElements);
            const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

            const modalExtra = $('#modal-window-2');
            const modalButtonExtra = $('#modal-button-2');
            const modalCloseExtra = $('#modal-window-2 .close');
            $('#modal-window-2').attr('aria-hidden','false').attr('aria-label','Learn more about honda service pass').attr('role','dialog');
            $('#modal-window-2 .close').attr('tabindex',"0").attr('role','button').attr('aria-label','close modal')

            const showModalExtra = () => {
                $('#modal-window-2').attr('aria-hidden','true');
                modalExtra.show();
                modalCloseExtra.focus();
            }

            const hideModalExtra = () => {
                $('#modal-window-2').attr('aria-hidden','false');
                modalExtra.hide();
                modalButtonExtra.focus();
            }
            $(document.body).on("click", "#modal-button-2", function() {
                showModalExtra();
            });

            $(document.body).on("click", "#modal-window-2 .close", function() {
                hideModalExtra();
            });

            $(document.body).on('keypress', "#modal-window-2 .close", function(e) {
                if(e.which == 13) {
                    $(this).trigger('click');
                }
            });

            const trapmodalExtra = document.querySelector('#modal-window-2');

            window.onclick = function(event) {
                if (event.target == trapmodalExtra) {
                    hideModalExtra();
                }
            };

            const focusableElementsExtra = '[href], [tabindex]:not([tabindex="-1"])';
            const firstFocusableElementExtra = trapmodalExtra.querySelectorAll(focusableElementsExtra)[0]; // get first element to be focused inside modal
            const focusableContentExtra = trapmodalExtra.querySelectorAll(focusableElementsExtra);
            const lastFocusableElementExtra = focusableContentExtra[focusableContentExtra.length - 1]; // get last element to be focused inside modal
            
            document.addEventListener('keydown', function(e) {
            let isTabPressed = e.key === 'Tab' || e.keyCode === 9;
            
            if (!isTabPressed) {
                return;
            }
            
            if (e.shiftKey) { // if shift key pressed for shift + tab combination
                if (document.activeElement === firstFocusableElement || document.activeElement === firstFocusableElementExtra) {
                lastFocusableElement.focus(); // add focus for the last focusable element
                e.preventDefault();
                }
            } else { // if tab key is pressed
                if (document.activeElement === lastFocusableElement || document.activeElement === lastFocusableElementExtra) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
                firstFocusableElement.focus(); // add focus for the first focusable element
                e.preventDefault();
                }
            }
            });
            
            firstFocusableElement.focus();
            firstFocusableElementExtra.focus();
        });

        function loadScriptYT() {
            if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
                var tag = document.createElement('script');
                tag.src = "https://www.youtube.com/iframe_api";
                var firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            }
        }

        var ytPlayer;
        function onYouTubeIframeAPIReady() {
            ytPlayer = new YT.Player('vehicleYT', {
              events: {
                'onReady': onPlayerReady,
                'onStateChange': onYouTubePlayerStateChange
              }
            });
          }

        function onPlayerReady(event) {
            event.target.playVideo();
            var id = ytPlayer.getVideoData()['video_id'];
          
          
            if (id == "K5Q2ko-_EZ4") {
              _satellite.setVar('videoname', "'Joswin' – Honda Certified Pre-Owned Vehicles");
            }
            _satellite.track('torch_video_start');
        }

        function onYouTubePlayerStateChange(event) {

            if (event.data == YT.PlayerState.ENDED) {
              var id = ytPlayer.getVideoData()['video_id'];
          
          
              if (id == "K5Q2ko-_EZ4") {
                _satellite.setVar('videoname', "'Joswin' – Honda Certified Pre-Owned Vehicles");
              }
              _satellite.track('torch_video_complete');
            }
          }

        // recently viewed See details delay to allow TAP to fire
        // $(document.body).on("click", ".viewed-vehicle a", function() {
        //     setTimeout(() => {
        //         window.location.href =  $(this).attr('data-destination');
        //     }, 600);
        // });

        function randomizer() {
            //get random src for video
            //only fire once 

                var videoSrc = new Array ();
                videoSrc[0] = "/images/home/Videos/Cafe";
                videoSrc[1] = "/images/home/Videos/Garage";
    
                var size = videoSrc.length
                var x = Math.floor(size*Math.random())
                           
            // console.log('VIDEO SOURCE:   ' , videoSrc[x] )
            return videoSrc[x];

        }

        // Add the appropriate video source to the DOM
        function getVideo() {


            var videoSrc = new Array();
            videoSrc[0] = "/images/home/Videos/Cafe";
            videoSrc[1] = "/images/home/Videos/Garage";

            var size = videoSrc.length
            var x = Math.floor(size * Math.random())

            console.log('VIDEO SOURCE:   ', videoSrc[x])
            return videoSrc[x];
            // }
        }

        // Add the appropriate video source to the DOM
        // function getVideo() {


        //     $('#video > source').remove();

        //     if ($(window).width() < 768) {
        //         // mobile portrait orientation
        //         if (window.innerHeight > window.innerWidth) {
        //             $('.overlay').css({
        //                 "width": "100%",
        //                 "overflow": "hidden",
        //                 "display": "block",
        //                 "height": "432px"
        //             });
        //             $('#video').css({
        //                 "width": "768px !important",
        //                 "height": "432px !important"
        //             });
        //             $('#video').append("<source type='video/mp4' src=" + randomizer() + "/768x432.mp4 />");
        //         }
        //         // mobile landscape orientation
        //         if (window.innerWidth > window.innerHeight) {
        //             $('#video').css({
        //                 "width": "100% !important",
        //                 "height": "auto !important"
        //             });
        //             $('#video').append("<source type='video/mp4' src=" + randomizer() + "/640x360.mp4 />");
        //         }
        //     } else if ($(window).width() > 767 && $(window).width() < 992) {
        //         // tablet
        //         $('#video').append("<source type='video/mp4' src=" + randomizer() + "/768x432.mp4 />");
        //     } else {
        //         // desktop
        //         $('#video').append("<source type='video/mp4' src=" + randomizer() + "/1440x810.mp4 />");
        //     }

        // }

        // If device autoplays video, then show it
        // function showVideo() {
        //     $('#video').show();
        // }

        // If device does not autoplay video, then serve up appropriate pano size
        // function showPano() {
        //     var size = window.innerWidth || document.body.clientWidth;

        //     if (size <= 767) {
        //         $('.video-pano').css({
        //             'background-image': 'url(/images/home/pano-mobile.jpg)',
        //             'height': '460px'
        //         });
        //     } else if (size <= 991) {
        //         $('.video-pano').css({
        //             'background-image': 'url(/images/home/pano-tablet.jpg)',
        //             'height': '599px'
        //         });
        //     } else {
        //         $('.video-pano').css({
        //             'background-image': 'url(/images/home/pano-desktop.jpg)',
        //             'height': '760px'
        //         });
        //     }
        // }

    }


}

// Geo code New
function initPage() {

    home.initPage(localStorageGetItemCommon("_GeoCurrentZipCode"));
}



$(document).ready(function () {
    /* tabs control */

    const tabs = document.querySelectorAll('[role="tab"]');
    const tabList = document.querySelector('[role="tablist"]');

    // Add a click event handler to each tab

    tabs.forEach((tab) => {
        tab.addEventListener("click", changeTabs);
        tab.addEventListener("keydown", (e) => {
            if (e.key === "Enter") {
                changeTabs(e);
            }
        });
    });

    // Enable arrow navigation between tabs in the tab list
    let tabFocus = 0;

    tabList.addEventListener("keydown", (e) => {
        // Move right
        if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
        tabs[tabFocus].setAttribute("tabindex", -1);
        if (e.key === "ArrowRight") {
            tabFocus++;
            // If we're at the end, go to the start
            if (tabFocus >= tabs.length) {
            tabFocus = 0;
            }
            // Move left
        } else if (e.key === "ArrowLeft") {
            tabFocus--;
            // If we're at the start, move to the end
            if (tabFocus < 0) {
            tabFocus = tabs.length - 1;
            }
        }

        tabs[tabFocus].setAttribute("tabindex", 0);
        tabs[tabFocus].focus();
        }
    });
});

var programsNavigate = (diff) => {
    const pBoxCollection = $(".fnsb-item-programs");
    pListIndex += diff;
    if (pListIndex >= pBoxCollection.length) {
        pListIndex = 0;
    }
    if (pListIndex < 0) {
        pListIndex = pBoxCollection.length - 1;
    }
    pBoxCollection.removeClass(cssClass).attr('aria-selected',false).eq(pListIndex).addClass(cssClass).attr('aria-selected',true).focus();
}

var vehiclesNavigate = (diff) => {
    const vBoxCollection = $(".fnsb-item-vehicles");
    vListIndex += diff;
    if (vListIndex >= vBoxCollection.length) {
        vListIndex = 0;
    }
    if (vListIndex < 0) {
        vListIndex = vBoxCollection.length - 1;
    }
    vBoxCollection.removeClass(cssClass).attr('aria-selected',false).eq(vListIndex).addClass(cssClass).attr('aria-selected',true).focus();
}



function changeTabs(e) {
    const target = e.target;
    const parent = target.parentNode;
    const grandparent = parent.parentNode;
    // console.log(target)
  
    if (!$(target).hasClass('selected')) {
        $(".type-cta, .vehicles-cta").toggleClass("selected");
        $(".vehicle-model, .vehicle-type").toggle();
    }
    // Remove all current selected tabs
    parent
      .querySelectorAll('[aria-selected="true"]')
      .forEach((t) => t.setAttribute("aria-selected", false));
  
    // Set this tab as selected
    target.setAttribute("aria-selected", true);
  }